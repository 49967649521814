import { Text } from '@atlaskit/primitives';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import SectionMessage from '@atlaskit/section-message';

const i18n = defineMessages({
	errorDescription: {
		id: 'excerpt-include.recursive-error.description',
		defaultMessage: 'This insert excerpt macro cannot insert an excerpt that it is nested within.',
		description:
			'This error message is shown within an insert excerpt macro when the user tries to insert an excerpt that is nested within the same excerpt.',
	},
});

export const RecursiveExcerptError = () => {
	const intl = useIntl();

	return (
		<SectionMessage appearance="error">
			<Text as="p">{intl.formatMessage(i18n.errorDescription)}</Text>
		</SectionMessage>
	);
};
