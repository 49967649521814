import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl-next';
import type { WrappedComponentProps, IntlShape } from 'react-intl-next';
import { css } from '@compiled/react';

import type { ADNode } from '@atlaskit/editor-common/validator';
import EditorEditIcon from '@atlaskit/icon/core/edit';
import { token } from '@atlaskit/tokens';

import { ExtensionLoadingComplete, ExtensionSSRMark } from '@confluence/extensions-performance';
import type { Mode } from '@confluence/macro-tracker';
import {
	getMacroAttributesFromADFNode,
	MacroExperienceSuccess,
	getExperienceName,
} from '@confluence/macro-tracker';

import type { ExcerptIncludeProps } from './types';
import { panel, panelBody, panelHeader, panelHeaderExcerpt } from './styles';
import { i18n } from './i18n';

type ExcerptIncludeComponentProps = ExcerptIncludeProps & {
	adf?: ADNode | ADNode[];
	pageId: string;
	shouldPanelBeDisplayed: boolean;
	displayInline: boolean;
	excerptName?: string;
	targetContentTitle: string;
	macroId: string;
	mediaToken?: string;
	isLivePage?: boolean;
};

class ExcerptIncludeRendererComponent extends Component<
	WrappedComponentProps & ExcerptIncludeComponentProps
> {
	render() {
		const {
			adf,
			contentId,
			pageId,
			shouldPanelBeDisplayed,
			excerptName,
			targetContentTitle,
			mode,
			extensionKey,
			macroNode,
			macroId,
			RendererWrapper,
			isLegacyRenderer,
			mediaToken,
			isLivePage,
			intl,
		} = this.props;
		const attributes = getMacroAttributesFromADFNode(macroNode);

		/**
		 * Returns true if:
		 * - ADF is non null AND
		 * - IF excerpt is untitled, that its content is not empty
		 * This is because the renderer can return empty ADF for null excerpts
		 */
		const hasExcerpt = (): boolean => {
			const emptyAdf = [{ type: 'paragraph' }];
			return !!adf && (!!excerptName || JSON.stringify(adf) !== JSON.stringify(emptyAdf));
		};

		const excerptIncludeStyle = css({ minWidth: 0 }) as any;

		const getExcerptBody = (): ReactNode => {
			return !hasExcerpt() ? (
				<ExcerptIncludeEmpty
					mode={mode}
					shouldPanelBeDisplayed={shouldPanelBeDisplayed}
					isLivePage={isLivePage}
					intl={intl}
				/>
			) : (
				<RendererWrapper
					adf={adf!}
					targetContentId={contentId}
					mediaToken={mediaToken}
					isInsideOfInlineExtension={!shouldPanelBeDisplayed}
				/>
			);
		};

		// WS-2563 Adding class to Excerpt Include HTML so it can be excluded from highlights
		return (
			<div
				data-fabric-macro={macroId}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="ak-excerpt-include ak-renderer-extension"
				data-testid="excerpt-include-component"
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				css={excerptIncludeStyle}
			>
				{isLegacyRenderer && (
					<ExtensionLoadingComplete
						contentId={pageId}
						extensionKey={extensionKey!}
						node={macroNode}
					/>
				)}
				{shouldPanelBeDisplayed ? (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					<span style={panel}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
						<span style={panelHeader}>
							{targetContentTitle}
							{hasExcerpt() && (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								<span style={panelHeaderExcerpt}>
									| {excerptName ?? intl.formatMessage(i18n.untitledExcerpt)}
								</span>
							)}
						</span>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
						<span style={panelBody}>{getExcerptBody()}</span>
					</span>
				) : (
					getExcerptBody()
				)}
				<ExtensionSSRMark macroId={macroId} renderType="ssr-new" />
				<MacroExperienceSuccess
					contentId={pageId}
					extensionKey={extensionKey}
					mode={mode}
					attributes={attributes}
					name={getExperienceName(mode, macroNode)}
				/>
			</div>
		);
	}
}

type ExcerptIncludeEmptyProps = {
	mode: Mode;
	shouldPanelBeDisplayed: boolean;
	isLivePage?: boolean;
	intl: IntlShape;
};

class ExcerptIncludeEmpty extends Component<ExcerptIncludeEmptyProps> {
	render() {
		const { mode, shouldPanelBeDisplayed, isLivePage, intl } = this.props;

		const emptyContentStyle = css({
			display: 'flex',
			alignItems: 'center',
			padding: token('space.050'),
			color: token('color.text.subtle'),
		}) as any;

		return (
			(shouldPanelBeDisplayed || (mode === 'editor' && !isLivePage)) && ( // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
				<span css={emptyContentStyle}>
					{intl.formatMessage(i18n.emptyMacro)}
					{mode === 'editor' &&
						!isLivePage &&
						intl.formatMessage(i18n.emptyMacroEdit, {
							editIcon: <EditorEditIcon label="edit" color={token('color.icon')} />,
						})}
				</span>
			)
		);
	}
}

export const ExcerptIncludeComponent = injectIntl(ExcerptIncludeRendererComponent);
